import React, { useRef, useEffect, useState } from "react";
import { ReactComponent as Model } from "./img/model.svg";
import "normalize.css";
import "./App.css";

const App = () => {
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const emailRef = useRef(null);
  useEffect(() => {
    if (emailRef.current) {
      emailRef.current.focus();
    }
  }, []);
  const checkForSubmit = e => {
    if (error) {
      setError(null);
    }
    if (e.keyCode === 13) {
      e.preventDefault();
      submit();
    }
  };
  const submit = () => {
    const email = emailRef.current.value;
    if (email) {
      setError(null);
      setSubmitting(true);
      fetch(`/.netlify/functions/signup`, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          email_address: email,
        }),
      }).then(res => {
        try {
          res.json()
          .then(data => {
            // console.log(data);
            if(data){
              if(data.status !== "subscribed"){
                setSubmitting(false)
                switch (data.title) {
                  case "Member Exists":
                    setSubmitted(true)
                    break;
                  default:
                    setError(data.detail || "Something went wrong while trying to sign you up. Please try again.")
                }
                window.gat('send', {
                  hitType: 'event',
                  eventCategory: 'Signup',
                  eventAction: 'Caught Error',
                  eventLabel: 'Pre-kickstart signups'
                })
              }else{
                // console.log(data);
                setSubmitted(true);
                setSubmitting(false);
                window.gat('send', {
                  hitType: 'event',
                  eventCategory: 'Signup',
                  eventAction: 'Success',
                  eventLabel: 'Pre-kickstart signups'
                })
                console.log("Successful Submit");
              }
            }})
            .catch(e => {
              console.error(e);
              setError(
                "Something went wrong while trying to sign you up. Please try again."
              )
              window.gat('send', {
                hitType: 'event',
                eventCategory: 'Signup',
                eventAction: 'Uncaught Error',
                eventLabel: 'Pre-kickstart signups'
              })
            })
        } catch (e) {
          console.error(e);
          setError(
            "Something went wrong while trying to sign you up. Please try again."
          );
        }
      });
    } else {
      setError("Please provide a valid email address");
    }
    window.gat('send', {
      hitType: 'event',
      eventCategory: 'Signup',
      eventAction: 'Triggered Submit',
      eventLabel: 'Pre-kickstart signups'
    })
  };
  return (
    <main>
      <div className="title-wrapper">
        <h1 className="header">JOOK</h1>
        <span className="subline">Analog music, made modern</span>
        <span className="subline">Coming soon. Sign up below for updates.</span>
      </div>
      {submitted && (
        <div className="success">
          <h2>You're all signed up!</h2>
          <p>
            For more updates follow us on{" "}
            <a href="https://www.twitter.com/the_jookbox">
              Twitter @the_jookbox
            </a>
          </p>
        </div>
      )}
      {!submitted && (
        <form className="form-wrapper">
          <input
            ref={emailRef}
            onKeyDown={checkForSubmit}
            className="email"
            name="email"
            id="email"
            type="text"
            placeholder="email@example.com"
          />
          {error && <span className="error">{error}</span>}
          <div className="button-wrapper">
            <button
              disabled={submitting}
              className="submit-button"
              type="button"
              onClick={submit}
            >
              {submitting ? "Submitting" : "Sign me up"}
            </button>
          </div>
        </form>
      )}
      <div className="img-wrapper">
        <Model
          // src={modelSrc}
          alt="An artistic rendering of the Jook device prototype."
        />
      </div>
    </main>
  );
};

export default App;
